import axios from '@/plugins/axios'

const getCurrencies = async () =>
{
    try
    {
        const result = await axios.get('currencies')
        return result.data;
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}


const getExchanges = async (category) =>
{
    try
    {
        const result = await axios.get('exchangers?search=' + category)
        return result.data;
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}


const getBlogs = async () =>
{
    try
    {
        const result = await axios.get('blogs')
        return result.data;
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const getPrices = async () =>
{
    try
    {
        const result = await axios.get('prices')
        return result.data;
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}


const getBlogDetail = async (id) =>
{
    try
    {
        const result = await axios.get('singleBlog?id=' + id)
        return result.data;
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}


const contactUs = async (data) =>
{
    try
    {
        const result = await axios.post('contactUs', data)
        return result.data;
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const newsletter = async (data) =>
{
    try
    {
        const result = await axios.post('subscribeNewsletter', data)
        return result.data;
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const applyForExchange = async (data) =>
{
    try
    {
        const result = await axios.post('applyForExchange', data)
        return result.data;
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const membersStatusCount = async (id) =>
{
    try
    {
        const result = await axios.get('membersStatusCount')
        return result.data;
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}


const fees = async () =>
{
    try
    {
        const result = await axios.get('fees')
        return result.data;
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const cryptoPrices = async () =>
{
    try
    {
        const result = await axios.get('cryptoPrices')
        return result.data;
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const team = async () =>
{
    try
    {
        const result = await axios.get('team')
        return result.data;
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}




export default {
    getCurrencies,
    getExchanges,
    contactUs,
    getBlogs,
    getBlogDetail,
    getPrices,
    applyForExchange,
    membersStatusCount,
    newsletter,
    fees,
    cryptoPrices,
    team
}
