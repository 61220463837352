<template>
    <v-app>
        <HeaderNav />
        <v-main>
            <!-- <HomeRoot /> -->
            <router-view></router-view>
            <!-- <AboutRoot /> -->
        </v-main>
        <Footer />
    </v-app>
</template>

<script>
import HeaderNav from '@/views/common/HeaderNav.vue';
import Footer from '@/views/common/Footer.vue';
export default {
    name: 'App',
    components: { HeaderNav, Footer },
    data: () => ({
        resolveLayoutVariant: null
    }),
    methods: {

    }
};
</script>
<style>
.v-application {
    font-family: 'Rubik', sans-serif !important;
}
</style>