<template>
    <v-app>
        <!-- <HeaderNav /> -->
        <!-- <v-main> -->
        <!-- <HomeRoot /> -->
        <!-- <router-view></router-view> -->
        <!-- <AboutRoot /> -->

        <!-- </v-main> -->
        <!-- <Footer /> -->

        <component :is="resolveLayoutVariant">
            <router-view></router-view>
        </component>
    </v-app>
</template>

<script>
import HeaderNav from '@/views/common/HeaderNav.vue';
import Footer from './views/common/Footer.vue';
import ContentLayout from './views/layouts/ContentLayout';
import PublicLayout from './views/layouts/PublicLayout';
// import router from './router';
export default {
    name: 'App',
    components: { HeaderNav, Footer, ContentLayout, PublicLayout },
    data: () => ({
        resolveLayoutVariant: 'ContentLayout'
    }),
    methods: {

    },
    created()
    {
        this.$watch(
            () => this.$route.params,
            (toParams, previousParams) =>
            {
                if (this.$route.meta.layout == 'public')
                {
                    this.resolveLayoutVariant = 'PublicLayout'
                }
                else
                {
                    this.resolveLayoutVariant = 'ContentLayout'
                }
            }
        )
    },
    mounted()
    {
        // window.$crisp = [];
        // window.CRISP_WEBSITE_ID = "adb2f631-6a56-4e5b-9d91-17ffa15ff097";
        // (function ()
        // {
        //     const d = document;
        //     const s = d.createElement("script");
        //     s.src = "https://client.crisp.chat/l.js";
        //     s.async = 1;
        //     d.getElementsByTagName("head")[0].appendChild(s);
        // })();
    },
};
</script>
<style>
.v-application {
    font-family: 'Rubik', sans-serif !important;
}
</style>