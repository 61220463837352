import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: 'home'
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/auth/Login.vue'),
        meta: {
            layout: 'public',
        },
    },
    {
        path: '/coming',
        name: 'coming',
        component: () => import('@/views/auth/ComingSoon.vue'),
        meta: {
            layout: 'public',
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@/views/auth/Register.vue'),
        meta: {
            layout: 'public',
        },
    },
    {
        path: '/home',
        name: 'HomeRoot',
        component: () => import('@/views/home/HomeRoot.vue'),
    },
    {
        path: '/about',
        name: 'AboutRoot',
        component: () => import('@/views/about/AboutRoot.vue'),
    },
    {
        path: '/blogs',
        name: 'BlogsRoot',
        component: () => import('@/views/blogs/BlogsRoot.vue'),
    },
    {
        path: '/blog-detail/:id',
        name: 'blogdetail',
        component: () => import('@/views/blogs/BlogDetail.vue'),
    },
    {
        path: '/prices',
        name: 'PricesRoot',
        component: () => import('@/views/prices/PricesRoot.vue'),
    },
    {
        path: '/fees',
        name: 'FeesRoot',
        component: () => import('@/views/fees/FeesRoot.vue'),
    },
    {
        path: '/apis',
        name: 'ApisRoot',
        component: () => import('@/views/apis/ApisRoot.vue'),
    },
    {
        path: '/exchangers',
        name: 'ExchangersRoot',
        component: () => import('@/views/exchangers/ExchangersRoot.vue'),
    },
    {
        path: '/exchangers-form',
        name: 'ExchangersForm',
        component: () => import('@/views/exchangers/ExchangersForm.vue'),
    },
    {
        path: '/faqs',
        name: 'FaqsRoot',
        component: () => import('@/views/faqs/FaqsRoot.vue'),
    },
    {
        path: '/about',
        name: 'aboutteam',
        component: () => import('@/views/about/AboutRoot.vue'),
    },
    {
        path: '/aboutus',
        name: 'aboutus',
        component: () => import('@/views/aboutus/AboutusRoot.vue'),
    },
    {
        path: '/contactus',
        name: 'contactus',
        component: () => import('@/views/contactus/ContactusRoot.vue'),
    },
    {
        path: '/privacypolicy',
        name: 'privacypolicy',
        component: () => import('@/views/privacypolicy/PrivacypolicyRoot.vue'),
    },
    {
        path: '/apiguide',
        name: 'apiguide',
        component: () => import('@/views/static/ApiGuide.vue'),
    },
    {
        path: '/guard',
        name: 'guard',
        component: () => import('@/views/static/PaySafeGuard.vue'),
    },
    {
        path: '/paysafemoney',
        name: 'paysafemoney',
        component: () => import('@/views/static/PaySafeMoney.vue'),
    },
    //   {
    //     path: '*',
    //     redirect: 'HomeRoot',
    //   },
    {
        path: '*',
        redirect: to =>
        {
            // const userData = localStorage.getItem('accessToken')
            // const userRole = userData && userData.role ? userData.role : null

            // if (userData) return { name: 'dashboard' }

            // if (userRole === 'client') return { name: 'page-access-control' }

            return { name: 'HomeRoot' }
        },
    },
]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior()
    {
        return { x: 0, y: 0 }
    },
})


export default router
