import axios from 'axios';

axios.defaults.baseURL = 'https://wecoinnetwork.com/weCoinApp/api/enrollment/';

// axios.interceptors.request.use(config =>
// {
//     let token = localStorage.getItem('authentication');
//     if (!!token) config.headers = { authentication: token, language: 'ar' }
//     return config;
// });

// axios.interceptors.response.use(response =>
// {
//     return response;
// },
//     error =>
//     {
//         if ('401' == error.response.status) accountServices.logout();
//         return Promise.resolve({ error });
//     });

export default axios;
