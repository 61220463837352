<template>
    <div class="ekit-template-content-markup ekit-template-content-header ekit-template-content-theme-support">
        <div data-elementor-type="wp-post"
             data-elementor-id="62"
             class="elementor elementor-62">
            <section class="elementor-section elementor-top-section elementor-element elementor-element-af352d3 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default tf-sticky-section tf-sticky-no"
                     data-id="af352d3"
                     data-element_type="section"
                     data-settings="{&quot;tf_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-cdb9982"
                         data-id="cdb9982"
                         data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-bc955f0 elementor-invisible elementor-widget elementor-widget-image"
                                 data-id="bc955f0"
                                 data-element_type="widget"
                                 data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;}"
                                 data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <a href="#/home">
                                        <img width="199"
                                             height="33"
                                             src="@/assets/wp-content/uploads/2022/04/A-LOGO-1.png"
                                             class="attachment-full size-full wp-image-320"
                                             alt="" /> </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-9eb6eff"
                         data-id="9eb6eff"
                         data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-8016d73 elementor-hidden-mobile elementor-invisible elementor-widget elementor-widget-ekit-nav-menu"
                                 data-id="8016d73"
                                 data-element_type="widget"
                                 data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}"
                                 data-widget_type="ekit-nav-menu.default">
                                <div class="elementor-widget-container">
                                    <div class="ekit-wid-con ekit_menu_responsive_tablet"
                                         data-hamburger-icon=""
                                         data-hamburger-icon-type="icon"
                                         data-responsive-breakpoint="1024"> <button
                                                class="elementskit-menu-hamburger elementskit-menu-toggler"
                                                type="button"
                                                aria-label="hamburger-icon">
                                            <span class="elementskit-menu-hamburger-icon"></span><span
                                                  class="elementskit-menu-hamburger-icon"></span><span
                                                  class="elementskit-menu-hamburger-icon"></span>
                                        </button>
                                        <div id="ekit-megamenu-main-menu"
                                             class="elementskit-menu-container elementskit-menu-offcanvas-elements elementskit-navbar-nav-default ekit-nav-menu-one-page-no ekit-nav-dropdown-hover">
                                            <ul id="menu-main-menu"
                                                class="elementskit-navbar-nav elementskit-menu-po-right submenu-click-on-icon">
                                                <li id="menu-item-81"
                                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-60 current_page_item menu-item-81 nav-item elementskit-mobile-builder-content active"
                                                    data-vertical-menu=750px><a href="#/home"
                                                       class="ekit-menu-nav-link active">Home</a></li>
                                                <li id="menu-item-80"
                                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-80 nav-item elementskit-mobile-builder-content"
                                                    data-vertical-menu=750px><a href="#/aboutus"
                                                       class="ekit-menu-nav-link">About</a></li>
                                                <!-- <li id="menu-item-79"
                                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-79 nav-item elementskit-mobile-builder-content"
                                                    data-vertical-menu=750px><a href="services/index.html"
                                                       class="ekit-menu-nav-link">Services</a></li> -->
                                                <li id="menu-item-79"
                                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-79 nav-item elementskit-mobile-builder-content"
                                                    data-vertical-menu=750px><a href="#/about"
                                                       class="ekit-menu-nav-link">Team</a></li>
                                                <li id="menu-item-78"
                                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-78 nav-item elementskit-mobile-builder-content"
                                                    data-vertical-menu=750px><a href="#/faqs"
                                                       class="ekit-menu-nav-link">FAQs</a></li>
                                                <li id="menu-item-77"
                                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-77 nav-item elementskit-mobile-builder-content"
                                                    data-vertical-menu=750px><a href="#/contactus"
                                                       class="ekit-menu-nav-link">Contact</a></li>
                                                <!-- <li id="menu-item-4444"
                                                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-4444 nav-item elementskit-dropdown-has relative_position elementskit-dropdown-menu-default_width elementskit-mobile-builder-content"
                                                    data-vertical-menu=750px><a href="#"
                                                       class="ekit-menu-nav-link ekit-menu-dropdown-toggle">More<i
                                                           aria-hidden="true"
                                                           class="icon icon-plus elementskit-submenu-indicator"></i></a>
                                                    <ul class="elementskit-dropdown elementskit-submenu-panel">
                                                        <li id="menu-item-4411"
                                                            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4411 nav-item elementskit-mobile-builder-content"
                                                            data-vertical-menu=750px><a href="team/index.html"
                                                               class=" dropdown-item">Team</a>
                                                        <li id="menu-item-5423"
                                                            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-5423 nav-item elementskit-mobile-builder-content"
                                                            data-vertical-menu=750px><a href="testimonial/index.html"
                                                               class=" dropdown-item">Testimonial</a>
                                                        <li id="menu-item-4425"
                                                            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4425 nav-item elementskit-mobile-builder-content"
                                                            data-vertical-menu=750px><a href="products/index.html"
                                                               class=" dropdown-item">Products</a>
                                                        <li id="menu-item-4418"
                                                            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4418 nav-item elementskit-mobile-builder-content"
                                                            data-vertical-menu=750px><a href="faq/index.html"
                                                               class=" dropdown-item">Faq</a>
                                                        <li id="menu-item-4422"
                                                            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4422 nav-item elementskit-mobile-builder-content"
                                                            data-vertical-menu=750px><a href="how-its-work/index.html"
                                                               class=" dropdown-item">How Its Work</a>
                                                        <li id="menu-item-4437"
                                                            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4437 nav-item elementskit-mobile-builder-content"
                                                            data-vertical-menu=750px><a href="coming-soon/index.html"
                                                               class=" dropdown-item">Coming Soon</a>
                                                        <li id="menu-item-4433"
                                                            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4433 nav-item elementskit-mobile-builder-content"
                                                            data-vertical-menu=750px><a href="error-404/index.html"
                                                               class=" dropdown-item">Error 404</a>
                                                    </ul>
                                                </li> -->
                                            </ul>
                                            <div class="elementskit-nav-identity-panel">
                                                <div class="elementskit-site-title">
                                                    <a class="elementskit-nav-logo"
                                                       href="https://elementorkits.evonicmeta.com/demo1"
                                                       target="_self"
                                                       rel="">
                                                        <img width="199"
                                                             height="33"
                                                             src="@/assets/wp-content/uploads/2022/04/logo_coinpce.png"
                                                             class="attachment-full size-full"
                                                             alt=""
                                                             decoding="async" />
                                                    </a>
                                                </div><button class="elementskit-menu-close elementskit-menu-toggler"
                                                        type="button">X</button>
                                            </div>
                                        </div>
                                        <div
                                             class="elementskit-menu-overlay elementskit-menu-offcanvas-elements elementskit-menu-toggler ekit-nav-menu--overlay">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-9ee15c1 elementor-hidden-desktop elementor-hidden-tablet elementor-widget elementor-widget-ekit-nav-menu"
                                 data-id="9ee15c1"
                                 data-element_type="widget"
                                 data-widget_type="ekit-nav-menu.default">
                                <div class="elementor-widget-container">
                                    <div class="ekit-wid-con ekit_menu_responsive_tablet"
                                         data-hamburger-icon=""
                                         data-hamburger-icon-type="icon"
                                         data-responsive-breakpoint="1024"> <button
                                                class="elementskit-menu-hamburger elementskit-menu-toggler"
                                                type="button"
                                                aria-label="hamburger-icon">
                                            <span class="elementskit-menu-hamburger-icon"></span><span
                                                  class="elementskit-menu-hamburger-icon"></span><span
                                                  class="elementskit-menu-hamburger-icon"></span>
                                        </button>
                                        <div id="ekit-megamenu-mobile-menu"
                                             class="elementskit-menu-container elementskit-menu-offcanvas-elements elementskit-navbar-nav-default ekit-nav-menu-one-page-no ekit-nav-dropdown-hover">
                                            <ul id="menu-mobile-menu"
                                                class="elementskit-navbar-nav elementskit-menu-po-center submenu-click-on-icon">
                                                <li id="menu-item-3341"
                                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-60 current_page_item menu-item-3341 nav-item elementskit-mobile-builder-content active"
                                                    data-vertical-menu=750px><a href="#/home"
                                                       class="ekit-menu-nav-link active">Home</a></li>
                                                <li id="menu-item-3340"
                                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-3340 nav-item elementskit-mobile-builder-content"
                                                    data-vertical-menu=750px><a href="#/aboutus"
                                                       class="ekit-menu-nav-link">About</a></li>
                                                <!-- <li id="menu-item-3339"
                                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-3339 nav-item elementskit-mobile-builder-content"
                                                    data-vertical-menu=750px><a href="services/index.html"
                                                       class="ekit-menu-nav-link">Services</a></li> -->
                                                <li id="menu-item-3342"
                                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-3342 nav-item elementskit-mobile-builder-content"
                                                    data-vertical-menu=750px><a href="#/about"
                                                       class="ekit-menu-nav-link">Team</a></li>
                                                <li id="menu-item-3337"
                                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-3337 nav-item elementskit-mobile-builder-content"
                                                    data-vertical-menu=750px><a href="#/faqs"
                                                       class="ekit-menu-nav-link">FAQs</a></li>
                                                <li id="menu-item-5424"
                                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-5424 nav-item elementskit-mobile-builder-content"
                                                    data-vertical-menu=750px><a href="#/contactus"
                                                       class="ekit-menu-nav-link">Contact</a></li>
                                                <!-- <li id="menu-item-3343"
                                                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-3343 nav-item elementskit-dropdown-has relative_position elementskit-dropdown-menu-default_width elementskit-mobile-builder-content"
                                                    data-vertical-menu=750px><a href="#"
                                                       class="ekit-menu-nav-link ekit-menu-dropdown-toggle">More<i
                                                           aria-hidden="true"
                                                           class="icon icon-plus elementskit-submenu-indicator"></i></a>
                                                    <ul class="elementskit-dropdown elementskit-submenu-panel">
                                                        <li id="menu-item-4412"
                                                            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4412 nav-item elementskit-mobile-builder-content"
                                                            data-vertical-menu=750px><a href="team/index.html"
                                                               class=" dropdown-item">Team</a>
                                                        <li id="menu-item-4419"
                                                            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4419 nav-item elementskit-mobile-builder-content"
                                                            data-vertical-menu=750px><a href="faq/index.html"
                                                               class=" dropdown-item">Faq</a>
                                                        <li id="menu-item-4423"
                                                            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4423 nav-item elementskit-mobile-builder-content"
                                                            data-vertical-menu=750px><a href="how-its-work/index.html"
                                                               class=" dropdown-item">How Its Work</a>
                                                        <li id="menu-item-4426"
                                                            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4426 nav-item elementskit-mobile-builder-content"
                                                            data-vertical-menu=750px><a href="products/index.html"
                                                               class=" dropdown-item">Products</a>
                                                        <li id="menu-item-4438"
                                                            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4438 nav-item elementskit-mobile-builder-content"
                                                            data-vertical-menu=750px><a href="coming-soon/index.html"
                                                               class=" dropdown-item">Coming Soon</a>
                                                        <li id="menu-item-4434"
                                                            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4434 nav-item elementskit-mobile-builder-content"
                                                            data-vertical-menu=750px><a href="error-404/index.html"
                                                               class=" dropdown-item">Error 404</a>
                                                    </ul>
                                                </li> -->
                                            </ul>
                                            <div class="elementskit-nav-identity-panel">
                                                <div class="elementskit-site-title">
                                                    <a class="elementskit-nav-logo"
                                                       href="https://elementorkits.evonicmeta.com/demo1"
                                                       target="_self"
                                                       rel="">
                                                        <img width="199"
                                                             height="33"
                                                             src="@/assets/wp-content/uploads/2022/04/logo_coinpce.png"
                                                             class="attachment-full size-full"
                                                             alt=""
                                                             decoding="async" />
                                                    </a>
                                                </div><button class="elementskit-menu-close elementskit-menu-toggler"
                                                        type="button">X</button>
                                            </div>
                                        </div>
                                        <div
                                             class="elementskit-menu-overlay elementskit-menu-offcanvas-elements elementskit-menu-toggler ekit-nav-menu--overlay">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-a9325df elementor-hidden-mobile"
                         data-id="a9325df"
                         data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-5f4cd55 elementor-align-right elementor-tablet-align-right elementor-invisible elementor-widget elementor-widget-button"
                                 data-id="5f4cd55"
                                 data-element_type="widget"
                                 data-settings="{&quot;_animation&quot;:&quot;fadeInRight&quot;}"
                                 data-widget_type="button.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-button-wrapper">
                                        <a class="elementor-button elementor-button-link elementor-size-sm elementor-animation-shrink"
                                           href="https://jhmnetwork.com/dashboard/">
                                            <span class="elementor-button-content-wrapper">
                                                <span class="elementor-button-text">Login</span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import router from '@/router';

export default {
    name: 'Header',
    data: () => ({
        menus: [
            { title: 'Home', route: '/home' },
            { title: 'About Team', route: '/about' },
            { title: 'Fees', route: '/fees' },
            { title: 'Exchangers', route: '/exchangers' },
            { title: 'Prices', route: '/prices' },
            { title: 'APIs', route: '/apis' },
            { title: 'FAQs', route: '/faqs' },
            { title: 'Blogs', route: '/blogs' },
        ]
    }),
    methods: {
        onMenu(route)
        {
            router.push(route)
        }
    }
};
</script>
<style></style>