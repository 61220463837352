import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import '@/styles/styles.scss'
import router from './router';

import sweetalert2 from './plugins/sweet-alert'

Vue.config.productionTip = false

new Vue({
    router: router,
    vuetify,
    sweetalert2,
    render: h => h(App),
  }).$mount('#app')
