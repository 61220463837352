<template>
    <router-view></router-view>
</template>

<script>
export default {
    name: 'App',
    data: () => ({
        resolveLayoutVariant: null
    }),
    methods: {

    }
};
</script>
<style>
.v-application {
    font-family: 'Rubik', sans-serif !important;
}
</style>