<template>
    <div class="ekit-template-content-markup ekit-template-content-footer ekit-template-content-theme-support">
        <div data-elementor-type="wp-post"
             data-elementor-id="230"
             class="elementor elementor-230">
            <section class="elementor-section elementor-top-section elementor-element elementor-element-6469553 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default tf-sticky-section tf-sticky-no"
                     data-id="6469553"
                     data-element_type="section"
                     data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;tf_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-no">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c5aa331"
                         data-id="c5aa331"
                         data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-ecf1ba2 elementor-section-boxed elementor-section-height-default elementor-section-height-default tf-sticky-section tf-sticky-no"
                                     data-id="ecf1ba2"
                                     data-element_type="section"
                                     data-settings="{&quot;tf_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-e00c03a"
                                         data-id="e00c03a"
                                         data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-f1c5a2d elementor-invisible elementor-widget elementor-widget-image"
                                                 data-id="f1c5a2d"
                                                 data-element_type="widget"
                                                 data-settings="{&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                 data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img width="199"
                                                         height="33"
                                                         src="@/assets/wp-content/uploads/2022/04/logo_coinpce.png"
                                                         class="attachment-full size-full wp-image-1363"
                                                         alt=""
                                                         loading="lazy" />
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-9a41449 elementor-invisible elementor-widget elementor-widget-heading"
                                                 data-id="9a41449"
                                                 data-element_type="widget"
                                                 data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}"
                                                 data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <p class="elementor-heading-title elementor-size-default">
                                                        JHM Network is a unique payment gateway, enjoy hassle free financing with your personal and business needs.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-082abfa elementor-hidden-mobile"
                                         data-id="082abfa"
                                         data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-c4ef07c elementor-invisible elementor-widget elementor-widget-heading"
                                                 data-id="c4ef07c"
                                                 data-element_type="widget"
                                                 data-settings="{&quot;_animation&quot;:&quot;fadeInDown&quot;}"
                                                 data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h6 class="elementor-heading-title elementor-size-default">
                                                        Quick Links</h6>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-b504def elementor-align-left elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-invisible elementor-widget elementor-widget-icon-list"
                                                 data-id="b504def"
                                                 data-element_type="widget"
                                                 data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}"
                                                 data-widget_type="icon-list.default">
                                                <div class="elementor-widget-container">
                                                    <link rel="stylesheet"
                                                          href="wp-content/plugins/elementor/assets/css/widget-icon-list.min.css">
                                                    <ul class="elementor-icon-list-items">
                                                        <li class="elementor-icon-list-item">
                                                            <a href="#/home">

                                                                <span class="elementor-icon-list-text">Home</span>
                                                            </a>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <a href="#/aboutus">

                                                                <span class="elementor-icon-list-text">About
                                                                    Us</span>
                                                            </a>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <a href="#/aboutus">

                                                                <span class="elementor-icon-list-text">Services</span>
                                                            </a>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <a href="#/faqs">

                                                                <span class="elementor-icon-list-text">Faq's</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-c256808"
                                         data-id="c256808"
                                         data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-a462d15 elementor-invisible elementor-widget elementor-widget-heading"
                                                 data-id="a462d15"
                                                 data-element_type="widget"
                                                 data-settings="{&quot;_animation&quot;:&quot;fadeInDown&quot;}"
                                                 data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h6 class="elementor-heading-title elementor-size-default">
                                                        Useful Links </h6>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-0ccd95b elementor-align-left elementor-mobile-align-center elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-invisible elementor-widget elementor-widget-icon-list"
                                                 data-id="0ccd95b"
                                                 data-element_type="widget"
                                                 data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}"
                                                 data-widget_type="icon-list.default">
                                                <div class="elementor-widget-container">
                                                    <ul class="elementor-icon-list-items">
                                                        <li class="elementor-icon-list-item">
                                                            <a href="#/privacypolicy">

                                                                <span class="elementor-icon-list-text">Privacy
                                                                    Policy</span>
                                                            </a>
                                                        </li>
                                                        <!-- <li class="elementor-icon-list-item">
                                                            <a href="#">

                                                                <span class="elementor-icon-list-text">Term &
                                                                    Condition</span>
                                                            </a>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <a href="#">

                                                                <span class="elementor-icon-list-text">Disclaimer</span>
                                                            </a>
                                                        </li> -->
                                                        <li class="elementor-icon-list-item">
                                                            <a href="#/contactus">

                                                                <span class="elementor-icon-list-text">Support</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-e6712a0 elementor-hidden-mobile"
                                         data-id="e6712a0"
                                         data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-f485677 elementor-invisible elementor-widget elementor-widget-heading"
                                                 data-id="f485677"
                                                 data-element_type="widget"
                                                 data-settings="{&quot;_animation&quot;:&quot;fadeInDown&quot;}"
                                                 data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h6 class="elementor-heading-title elementor-size-default">
                                                        contact us</h6>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-44ce93b elementor-align-left elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-invisible elementor-widget elementor-widget-icon-list"
                                                 data-id="44ce93b"
                                                 data-element_type="widget"
                                                 data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}"
                                                 data-widget_type="icon-list.default">
                                                <div class="elementor-widget-container">
                                                    <ul class="elementor-icon-list-items">
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <i aria-hidden="true"
                                                                   class="icon icon-map-marker1"></i> </span>
                                                            <span class="elementor-icon-list-text">DUBAI, United Arab Emirates.</span>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <i aria-hidden="true"
                                                                   class="icon icon-email1"></i> </span>
                                                            <span class="elementor-icon-list-text">
                                                                <a class="elementor-icon-list-text" href="mailto:info@jhmnetwork.com">info@jhmnetwork.com</a>
                                                            </span>
                                                        </li>
                                                        
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <section class="elementor-section elementor-top-section elementor-element elementor-element-5dc1430 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default tf-sticky-section tf-sticky-no"
                     data-id="5dc1430"
                     data-element_type="section"
                     data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;tf_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-97394ff"
                         data-id="97394ff"
                         data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-c8f67a9 elementor-invisible elementor-widget elementor-widget-text-editor"
                                 data-id="c8f67a9"
                                 data-element_type="widget"
                                 data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}"
                                 data-widget_type="text-editor.default">
                                <div class="elementor-widget-container">
                                    <p>Copyright © 2023 All Rights Reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-8e62691"
                         data-id="8e62691"
                         data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-c5cac36 elementor-invisible elementor-widget elementor-widget-elementskit-social-media"
                                 data-id="c5cac36"
                                 data-element_type="widget"
                                 data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}"
                                 data-widget_type="elementskit-social-media.default">
                                <div class="elementor-widget-container">
                                    <div class="ekit-wid-con">
                                        <ul class="ekit_social_media">
                                            <li class="elementor-repeater-item-bc9a715">
                                                <a href="#"
                                                   aria-label="instagram"
                                                   class="1">

                                                    <i aria-hidden="true"
                                                       class="icon icon-instagram-1"></i>
                                                </a>
                                            </li>
                                            <li class="elementor-repeater-item-34cebed">
                                                <a href="#"
                                                   aria-label="Facebook"
                                                   class="facebook">

                                                    <i aria-hidden="true"
                                                       class="icon icon-facebook"></i>
                                                </a>
                                            </li>
                                            <li class="elementor-repeater-item-d14490c">
                                                <a href="#"
                                                   aria-label="Twitter"
                                                   class="twitter">

                                                    <i aria-hidden="true"
                                                       class="icon icon-twitter"></i>
                                                </a>
                                            </li>
                                            <li class="elementor-repeater-item-2fcdb08">
                                                <a href="#"
                                                   aria-label="what&#039;s app"
                                                   class="whatsapp">

                                                    <i aria-hidden="true"
                                                       class="fab fa-whatsapp"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>

import apiServices from '@/services/api-services';

export default {
    data: () => ({
        component_heading: "CONTACT US",
        genericRules: [
            v => !!v || 'Field is required'
        ],
        valid: false,
        newsletter: '',
        heading: "Active Users",
        count: "98M+",
        // icons: [{ icon: 'mdi-twitter' }, { icon: 'mdi-send' }, { icon: 'mdi-instagram' }, { icon: 'mdi-linkedin' }, { icon: 'mdi-facebook' }]
        icons: [
            { link: 'https://twitter.com/wecoinnetwork', icon: 'mdi-twitter' },
            { link: 'https://www.instagram.com/wecoinnetwork/', icon: 'mdi-instagram' },
            { link: 'https://www.facebook.com/wecoinnetwork/', icon: 'mdi-facebook' }
        ]
    }),
    methods: {
        submit()
        {
            this.$refs.form.validate();
            if (this.$refs.form.validate())
            {
                apiServices.newsletter({ email: this.newsletter }).then(res =>
                {
                    if (res.statusCode == 200)
                    {
                        this.$swal({
                            title: `Newsletter`,
                            text: 'Subscribed successfully.',
                            confirmButtonColor: '#00b2a9',
                            icon: 'success',
                        })
                    }
                    else
                    {
                        this.$swal({
                            icon: 'error',
                            title: 'Newsletter',
                            confirmButtonColor: '#00b2a9',
                            text: 'Something went wrong!',
                        })
                    }
                });
            }
            else
            {
                this.$swal({
                    icon: 'error',
                    title: 'Newsletter',
                    confirmButtonColor: '#00b2a9',
                    text: 'Please fill out all fields.',
                })
            }
        },
    }
}
</script>
<style scoped>

</style>
